import { createContext, useEffect, useState } from 'react';
import FastQA from './question/FastQA';
const getEnvDash = (env) => {
    return (!env) || (env === "prod") ? "" : `${env}-`;
}
export const EnvironmentContext = createContext();

const FasqApp = (event) => {

    const [environment, setEnvironment] = useState(null)

    useEffect(() => {
        const env = event.dataset.env
        const envDash = getEnvDash(env)
        var environ
        switch (env) {
            case "":
            case "prod":
                environ = {
                    envDash: envDash,
                    wsUrl: `wss://${envDash}answers.answerpad.io`,
                    msPublicKey: "pk_3a9b74d41faa6972e4fd",
                };
            default:
                environ = {
                    envDash: envDash,
                    wsUrl: `wss://${envDash}answers.answerpad.io`,
                    msPublicKey: "pk_sb_6445c8177a71a0ef149d",
                };
        }
        setEnvironment(environ)
    }, [event])

    return (
        <EnvironmentContext.Provider value={environment}>
            <FastQA dataset={event.dataset} />
        </EnvironmentContext.Provider>
    )
}

export default FasqApp