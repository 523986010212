import axios from 'axios';

export const getOrgInfo = async (orgId) => {
    return axios.get(`https://apis.answerpad.io/admin/orgs/${orgId}/public`).then(response => {
        console.log('response', response.data);
        return response.data;
    }).catch(reason => {
        console.error(reason);
        return null;
    });
}

export const customizePage = () => {
    const queryParams = new URLSearchParams(window.location.search);
    const orgId = queryParams.get("orgId");
    if (!orgId) return
    getOrgInfo(orgId).then(publicInfo => {
        console.log("pulled custom properties: " + JSON.stringify(publicInfo));
        const orgIconDivs = document.querySelectorAll('.fasq-org-logo');
        const orgNameDivs = document.querySelectorAll('.fasq-org-name');
        const orgPrimaryDivs = document.querySelectorAll('.fasq-org-primary-color');
        const orgSecondaryDivs = document.querySelectorAll('.fasq-org-secondary-color');
        orgIconDivs.forEach(element => {
            if (element.tagName === 'IMG') {
                element.src = publicInfo.logo;
                element.alt = publicInfo.organizationName;
            }
        });
        orgNameDivs.forEach(element => {
            element.innerText = publicInfo.organizationName || "Please add a valid organization ID to access this page.";
        });
        orgPrimaryDivs.forEach(element => {
            try {
                console.log("primary ", element)
                element.style.backgroundColor = publicInfo.primaryColor;
            } catch (e) {
                console.error(e);
            }
        })
        orgSecondaryDivs.forEach(element => {
            try {
                console.log("secondary ", element)
                element.style.backgroundColor = publicInfo.secondaryColor;
                document.body.style.backgroundColor = publicInfo.primaryColor
            } catch (e) {
                console.error(e);
            }
        })
    }).catch(reason => {
        console.error(reason);
    });
}