import React, { useEffect, useState } from "react";
import logoAnimated from './fasq-logo-anim-64.gif';
import Markdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import { contextualOrgId } from "../../shared/utility.mjs";
import useFasq from "../client/useFasq.mjs";
import * as styles from '../../answerpad-widget.css'

const FastQA = (event) => {
    const [data, setData] = useState("");
    const [links, setLinks] = useState([]);
    const [query, setQuery] = useState();
    const [orgId, setOrgId] = useState()
    const [loading, setLoading] = useState(false)
    // const URL = 'wss://dev-answers.answerpad.io'
    // const [socketUrl] = useState(URL);
    const dataset = event.dataset;
    const env = dataset.env
    const getEnvDash = (env) => {
        return (!env) || (env === "prod") ? "" : `${env}-`;
    }
    const url = `wss://${getEnvDash(env)}answers.answerpad.io`
    const ask = useFasq(url)
    // const URL = 'ws://localhost:10000/'

    const onStart = () => {
        setData("")
        setLinks([])
        setLoading(true)
    }
    const onData = (data) => { setData(lastState => lastState + data) }
    const onLinks = (links) => { setLinks(links) }
    const onComplete = () => { setLoading(false) }
    const onError = (type) => {
        if (!type) { type = 'unknown' }
        switch (type) {
            case '403: Unauthorized':
                setData("Unauthorized - please make sure you are logged in");
                break;
            default:
                console.log('Error in service', type)
                setData("Unable to answer question at this time. Please try again later.")
        }
        setLoading(false)
    }

    const formatLinks = (linksArray) => {
        return linksArray.map((link, index) => (
            <div className={'w-fit truncate py-1 px-3 m-2 shadow-md no-underline rounded-full font-sans font-semibold text-sm border-blue bg-blue-100 btn-primary focus:outline-none active:shadow-none mr-2'} key={'answer-link-' + index}>
                <a key={'answer-url-' + index} className={`answerpad-answer-link`} href={link.source} target="_blank">
                    {link.title?.slice(0, 30) + (link.title.length > 30 ? '...' : '')}
                </a><br /></div>
        ))
    }

    return (
        <div className={"twp answerpadContainer"}>
            <div className={'answerpadQuestion'}>
                <input className={'answerpadQuestionInput'}
                    size={"lg"}
                    type="search"
                    defaultValue={query}
                    placeholder="Ask a question!"
                    aria-label="Ask"
                    onKeyUp={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            if ((e.target.value.length) > 0) {
                                setQuery(e.target.value)
                                if (!loading) { ask(contextualOrgId(dataset), query, onStart, onData, onLinks, onError, onComplete) }
                            }
                        }
                    }}
                    onChange={(e) => {
                        setQuery(e.target.value)
                    }} />
                <div className={'answerpadQuestionButton'}
                    onClick={() => {
                        if (!loading && query) {
                            ask(contextualOrgId(dataset), query, onStart, onData, onLinks, onError, onComplete)
                        }
                    }
                    }
                    role='button'
                    tabIndex={'0'}
                    onKeyUp={(e) => {
                        if (e.key === "Enter") {
                            e.preventDefault();
                            if ((e.target.value.length) > 0) {
                                setQuery(e.target.value)
                                if (!loading) { ask(contextualOrgId(dataset), query, onStart, onData, onLinks, onError, onComplete) }
                            }
                        }
                    }}
                >
                    {!loading &&
                        <span className="text-center align-middle p-0 m-0 text-clip">Ask</span>
                    }
                    {loading &&
                        <span className="text-center align-middle p-0 m-0">
                            <div class="bg-gradient-to-tr animate-spin from-[#06c] to-[#0c6] via-[white] rounded-full w-10 h-10 m-1 p-1">
                                <div class="bg-gray-200 rounded-full h-8 w-8">
                                    <div class="w-6 h-6 rounded-full"></div>
                                </div>
                            </div>
                        </span>
                    }
                </div>
            </div>
            <div className={'answerpadAnswer'}>
                <div className={'answerpadAnswerText text-wrap flex-wrap'}>
                    <Markdown className={'text-wrap flex-wrap overflow-x-auto'} remarkPlugins={[remarkGfm]}>{data}</Markdown>
                </div>
            </div>
            <div className={'flex'}>
                {formatLinks(links)}
            </div>
        </div >
    );
}

export default FastQA