import React from 'react';
import ReactDOM from 'react-dom/client';
import { customizePage } from './component/org/orgInfo.mjs'
import FasqApp from './component/FasqApp';

const fasqQueryDivs = document.querySelectorAll('.answerpad-widget');
fasqQueryDivs.forEach(div => {
  const root = ReactDOM.createRoot(div);
  const dataset = Object.fromEntries(Object.entries(div.dataset));

  root.render(
    < React.StrictMode >
      <FasqApp dataset={dataset} />
    </React.StrictMode >
  );
});

customizePage();  