export const useFasq = (wsUrl) => {
    const ask = (orgId, query, onStart, onData, onLinks, onError, onComplete, token) => {
        onStart()
        const socket = new WebSocket(wsUrl);
        socket.onopen = function (event) {
            const request = { question: query, orgId: orgId }
            if (token) { request.token = token }
            // console.log('', request)
            socket.send(JSON.stringify(request));
        };
        socket.onerror = function (error) {
            onError(error)
        };
        socket.onmessage = function (message) {
            try {
                const data = JSON.parse(message.data)
                if (data?.t) {
                    onData(data.t)
                } else if (data.links) {
                    onLinks(data.links)
                } else if (data.status) {
                    if (data.status === 'complete') {
                        onComplete()
                        socket.close()
                    }
                } else if (data?.message) {
                    onError("[end]")
                    console.log('Error in service', data.message)
                    socket.close()
                }
            } catch (e) {
                if (message.data === '403: Unauthorized') {
                    onError(message.data)
                } else {
                    onError(e)
                }
                socket.close()
            }
        };
    }
    return ask
}

export default useFasq